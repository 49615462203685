import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerStep2 } from "../redux/action/action";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import { useQuery } from "../helpers/helper";
import { ClipLoader } from "react-spinners";
import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";

function ConfirmOTP() {
  const { session, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState("");
  const [OTPError, setOTPError] = useState(false);
  const navigate = useNavigate();
  const query = useQuery();

  useEffect(() => {
    if (isEmpty(session)) return navigate("/");
  }, [navigate, session]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (OTP === "" || OTP.length < 4) return setOTPError("No OTP Entered.");
    dispatch(
      registerStep2(
        OTP,
        session,
        navigate,
        query.get("grant"),
        query.get("invite")
      )
    );
  };

  return (
    <div className="auth-page">
      <div className="auth-from  ">
        <form onSubmit={handleSubmit} className="container">
          <div className="flex justify-center">
            <img src="/images/cercle_x_logo.png" alt="logo" className="logo" />
          </div>

          <h4 className="flex column cercle-purple-text text-center">
            Confirm OTP
          </h4>

          <p className="text-center">Enter the OTP sent to your mobile</p>

          <div>
            <div className="input-field-style">
              <img src="../images/OTPIcon.svg" width={30} alt="otp" />
              <input
                className="browser-default"
                placeholder="Eg. 0 0 0 0 0 0"
                type="text"
                onChange={(e) => {
                  setOTP(e.target.value);
                  setOTPError("");
                }}
                value={OTP}
                maxLength="6"
              />
            </div>
            {OTPError && <p className="small-text red-text">{OTPError}</p>}
          </div>

          <button
            className="btn full-width mt-2"
            type="submit"
            disabled={loading || OTP.length !== 6}
          >
            {loading ? <ClipLoader color="#ffffff" size={20} /> : "Submit"}
          </button>

          <div className="mt-1 flex justify-center">
            <Footer />
          </div>
        </form>
      </div>
    </div>
  );
}

export default ConfirmOTP;
