export const authReducer = (
  state = {
    authenticated: false,
    loading: false,
    account: {},
    error: false,
    session: "",
    profile: {},
    referredFrom: "",
  },
  action
) => {
  switch (action.type) {
    case "REQUESTING_REGISTER_OTP":
      return {
        ...state,
        loading: true,
      };

    case "REQUESTED_REGISTER_OTP":
      return {
        ...state,
        loading: false,
        session: {
          phoneNumber: action.payload.phoneNumber,
          sessionId: action.payload.session,
        },
      };

    case "CANNOT_REQUEST_REGISTER_OTP":
      return {
        ...state,
        loading: false,
        error: true,
      };

    case "CONFIRMING_REGISTER_OTP":
      return {
        ...state,
        loading: true,
      };

    case "CONFIRMED_REGISTER_OTP":
      return {
        ...state,
        loading: false,
      };

    case "CANNOT_CONFIRM_REGISTER_OTP":
      return {
        ...state,
        loading: false,
        error: true,
      };

    case "CREATING_ACCOUNT":
      return {
        ...state,
        loading: true,
      };

    case "CREATED_ACCOUNT":
      return {
        ...state,
        loading: false,
        account: action.payload,
      };

    case "CANNOT_CREATE_ACCOUNT":
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
};
