import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useQuery } from "../helpers/helper";
import {
  isValidGrant,
  isValidInvite,
  registerStep1,
} from "../redux/action/action";
import { ClipLoader } from "react-spinners";
import Footer from "../components/Footer";

function SignUp() {
  const { loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();
  const [phoneNumber, setPhoneNumber] = useState("91");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [pageError, setPageError] = useState("");

  const grantId = query.get("grant");
  const invite = query.get("invite");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (phoneNumber.length < 10)
      return setPhoneNumberError("Invalid mobile number");
    dispatch(registerStep1(phoneNumber, navigate, grantId, invite));
  };

  useEffect(() => {
    if (grantId) {
      isValidGrant(grantId, setPageLoading, () => {
        setPageError("Sorry, this link is invalid or expired.");
      });
    }

    if (invite) {
      isValidInvite(invite, setPageLoading, () => {
        setPageError("Sorry, this link is invalid or expired.");
      });
    }
  }, [grantId, invite]);

  if (pageLoading) {
    return (
      <div className="auth-page">
        <ClipLoader color="cercle-purple" size={20} />
      </div>
    );
  }

  if (pageError) {
    return <div className="auth-page">{pageError}</div>;
  }

  return (
    <div className="auth-page">
      <div className="auth-from  ">
        <form onSubmit={handleSubmit} className="container ">
          <div className="flex justify-center">
            <img src="/images/cercle_x_logo.png" alt="logo" className="logo" />
          </div>

          <h4 className="flex column cercle-purple-text text-center">
            Sign In to Cercle X
          </h4>

          <p className="text-center">Enter your Mobile Number to proceed</p>

          <div className="mt-1">
            <PhoneInput
              inputProps={{ name: "phone", required: true, autoFocus: true }}
              className="browser-default"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e)}
              type="tel"
              inputClass="browser-default phone-input-style "
              dropdownStyle={{ backgroundColor: "#fff" }}
              buttonClass="phone-input-button-style"
              containerStyle={{ backgroundColor: "#fff" }}
              placeholder="Enter your phone number"
            />

            {phoneNumberError && (
              <p className="small-text red-text ">{phoneNumberError}</p>
            )}
          </div>

          <button
            className="btn full-width mb-1 mt-2 "
            type="submit"
            disabled={phoneNumberError || loading || phoneNumber.length < 10}
          >
            {loading ? <ClipLoader color="#fff" size={20} /> : "Sign In"}
          </button>

          <p className="semi-bold">
            Can't find your account?{" "}
            <Link to={"/"} className="primary cercle-purple-text underline">
              Click Here
            </Link>{" "}
          </p>

          <div className="mt-1 flex justify-center">
            <Footer />
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUp;
