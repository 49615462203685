import React from "react";
import { useMediaQuery } from "react-responsive";

function Footer() {
  const isTablet = useMediaQuery({ query: "(max-width:790px)" });

  return (
    <footer>
      <p className={isTablet ? "small-text mt-2 " : "small-text"}>
        All Rights Reserved © {new Date().getFullYear()}
        <a href="https://cerclex.com/" className="purple-text">
          &nbsp; Infinite Cercle Private Limited
        </a>
      </p>
    </footer>
  );
} 

export default Footer;
