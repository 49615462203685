import React from 'react'
import {Link} from 'react-router-dom'


function Error() {
  return (
    <div className='error-page full-width ' >
      <b className='normal-size '>Opps page not found...</b>
      <img src='../images/error-image.png'className='error-image' alt='error-page'/>
      <div className='btn'>
        <Link to='/' style={{color:"#fff"}} className='mb-4  ' >
        <p className='bold-title' >Go Back</p>
        </Link>
        </div>
    </div>
  )
}

export default Error

