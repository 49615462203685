import React, { useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { acceptInvitation } from "../redux/action/action";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

function AcceptInvitation() {
  const { invite, rs } = useParams();

  const dispatch = useDispatch();
  const [message, setMessage] = React.useState("Accepting Invitation...");

  useEffect(() => {
    if (!invite || !rs) return (window.location.href = "/error");
    dispatch(
      acceptInvitation(invite, rs, () => {
        setMessage("Failed to accept invitation");
      })
    );
  }, [dispatch, invite, rs]);
  return (
    <div className="loading-page">
      <div className="flex justify-center column align-center ">
        <img src="/images/logo.png" alt="logo" className="logo" />
        <p className="bold mt-1">{message} </p>

        {message === "Failed to accept invitation" ? (
          <button className="btn" onClick={() => (window.location.href = "/")}>
            Go Home
          </button>
        ) : (
          <>
            <BeatLoader color="#6f2da8" size={10} />
            <p className=" cercle-purple-text ">Loading...</p>
          </>
        )}
      </div>
    </div>
  );
}
export default AcceptInvitation;
