import { toast } from "react-toastify";
import React from "react";
import { useLocation } from "react-router-dom";

export const handleError = (error) => {
  toast.error(error.response ? error.response?.data : error.message);
};

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function notify(msg, bg) {
  toast(msg, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    progress: undefined,
  });
}

export function calculateTotal(scrapPrice, quantityInTonnes) {
  scrapPrice = parseInt(scrapPrice);
  let tonnesToKg = parseInt(quantityInTonnes) * 1000;

  return (tonnesToKg * scrapPrice).toLocaleString();
}

export function onNotificationClick(data, navigate) {
  const pickups = [
    "pickup_assigned",
    "pickup_completed",
    "pickup_requested",
    "driver_arrived",
    "pickup_ignored",
  ];

  if (pickups.includes(data.notificationType))
    return navigate("/timeline/" + data.pickup);

  return navigate("/pickups");
}

export function isValidEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function isValidPhoneNumber(phoneNumber) {
  // returns regex that checks if number is greater than 10 digits, and less than 15 digits,is a number and number must start with a plus sign and is not empty
  if (phoneNumber.split("")[0] !== "+") return false;
  return /^\+?[1-9]\d{9,14}$/.test(phoneNumber);
}

export const showFormError = (message, setter, ref, clear) => {
  setter(message);

  if (ref) {
    const input = ref.current.children[1];
    input.focus();
    input.select();
    input.placeholder = "Eg. +919876543210";
    clear("");
    ref.current.style.border = "1px solid tomato";
  }
  setTimeout(() => {
    setter("");
    if (ref) ref.current.style.border = "1px solid #e0e0e0";
  }, 5000);
};
