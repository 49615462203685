import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store from "./redux/store";
import ConfirmOTP from "./pages/ConfirmOTP";
import SignUp from "./pages/SignUp";
import AccountInformation from "./pages/AccountInformation";
import AccountCreated from "./pages/AccountCreated";
import AcceptInvitation from "./pages/AcceptInvitation";
import Error from "./pages/Error";

function App() {
  return (
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignUp />} />
          <Route path="/confirm-OTP/:session" element={<ConfirmOTP />} />
          <Route
            path="/account-information/:id"
            element={<AccountInformation />}
          />
          <Route path="/account-created" element={<AccountCreated />} />
          <Route
            path="/accept-invitation/:invite/:rs"
            element={<AcceptInvitation />}
          />
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
