import React, { useState } from "react";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { registerStep3 } from "../redux/action/action";
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useQuery } from "../helpers/helper";
import { useEffect } from "react";
import { ClipLoader } from "react-spinners";
import isEmpty from "lodash/isEmpty";
import { Checkbox } from "react-materialize";
import Footer from "../components/Footer";

function AccountInformation() {
  const navigate = useNavigate();
  const { session, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const query = useQuery();
  const grant = query.get("grant");
  const invite = query.get("invite");

  useEffect(() => {
    if (isEmpty(session)) return navigate("/");
  }, [navigate, session]);

  const validate = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
    } else {
      setPasswordError("password is not strong");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name === "") return setNameError("Please Enter Your Name");

    if (password === "") return setPasswordError("Please Enter Your Password");

    if (password !== confirmPassword)
      return setConfirmPasswordError("Passwords do not match");

    const data = {
      name,
      password,
      sessionId: session.sessionId,
    };
    dispatch(registerStep3(data, grant, invite, navigate));
  };

  return (
    <section className="auth-page">
      <div className="auth-from">
        <form onSubmit={handleSubmit} className="container">
          <div className="flex justify-center">
            <img src="/images/cercle_x_logo.png" alt="logo" className="logo" />
          </div>

          <h4 className="flex column cercle-purple-text text-center">
            Welcome to Cercle X
          </h4>

          <p className="text-center">
            Fill the form below to complete your sign up!
          </p>

          <div>
            <div className="input-field-style mb-1">
              <span className="material-symbols-outlined ">person</span>
              <input
                className="browser-default "
                placeholder="Enter Your Full Name"
                type="text"
                onChange={(e) => {
                  setName(e.target.value);
                  setNameError("");
                }}
                value={name}
              />
            </div>
            {nameError && <p className="small-text red-text">{nameError}</p>}
          </div>

          <div>
            <div className="input-field-style mb-1">
              <span className="material-symbols-outlined">lock</span>
              <input
                className="browser-default"
                placeholder="Enter Password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError("");
                  validate(e.target.value);
                }}
                value={password}
              />
              <span onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? (
                  <AiFillEye className="eye-icon pointer" />
                ) : (
                  <AiFillEyeInvisible className="eye-icon pointer" />
                )}
              </span>
            </div>
            {passwordError && (
              <p className="small-text red-text">{passwordError}</p>
            )}
          </div>

          <div>
            <div className="input-field-style mb-1">
              <span className="material-symbols-outlined  ">lock</span>
              <input
                className="browser-default"
                placeholder="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setConfirmPasswordError("");
                }}
                value={confirmPassword}
              />
              <div onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                {showConfirmPassword ? (
                  <AiFillEye className="eye-icon pointer" />
                ) : (
                  <AiFillEyeInvisible className="eye-icon pointer" />
                )}
              </div>
            </div>
            {confirmPasswordError && (
              <p className="small-text red-text">{confirmPasswordError}</p>
            )}
          </div>

          <div className="flex">
            <Checkbox
              checked={acceptTerms}
              onChange={() => setAcceptTerms(!acceptTerms)}
              filledIn={true}
              id="Checkbox_3"
              value={acceptTerms}
            />
            <p className="cercle-purple-text underline">
              Accept Terms and Conditions
            </p>
          </div>

          <button
            className="btn full-width mt-2"
            type="submit"
            disabled={
              !acceptTerms ||
              loading ||
              password.length < 8 ||
              name === "" ||
              confirmPassword.length < 8
            }
          >
            {loading ? <ClipLoader color="#fff" size={20} /> : "Next"}
          </button>
        <div className="mt-1 flex justify-center">
          <Footer />

        </div>
        </form>
      </div>
    </section>
  );
}

export default AccountInformation;
