import React from "react";

function AccountCreated() {
  return (
    <div className="access-denied-page">
      <div className="flex column align-center center">
        <h2 className="cercle-purple-text">Congratulations!</h2>
        <p className="bold">Your account has been created successfully.</p>
        <p>
          You can access all these suite of apps with your login credentials.
        </p>

        <img
          src="../images/sadAvatar.png"
          className="sadAvatarImage"
          alt="sadAvatar"
        />

        <div className="flex-wrap flex justify-center gap-1 mt-4 container ">
          <a href="https://dashboard.wasteknot.io/">
            <div className="app-box  ">
              <img
                src="../images/wasteknot_logo.png"
                className="app-logo"
                alt="drs-logo"
              />
              <div className="flex column align-start ">
                <b>Wasteknot Lite</b>
                <p className="small-text grey-text">
                  End to End Waste Management
                </p>
              </div>
            </div>
          </a>
          <a href="https://dashboard.wasteknot.io/">
            <div className="app-box  ">
              <img
                src="../images/wasteknot_logo.png"
                className="app-logo"
                alt="drs-logo"
              />
              <div className="flex column align-start ">
                <b>Wasteknot Plus</b>
                <p className="small-text grey-text">
                  Blockchain Enabled Waste Management
                </p>
              </div>
            </div>
          </a>

          <a href="https://play.google.com/store/apps/details?id=com.scrapmarketCCX">
            <div className="app-box">
              <img
                src="../images/scrapmarket.png"
                className="app-logo"
                alt="drs-logo"
              />
              <div className="flex column align-start ">
                <b>Scrap Trade </b>
                <p className="small-text grey-text">Buy & Sell Scrap</p>
              </div>
            </div>
          </a>

          <a href="https://www.metabins.co/">
            <div className="app-box  ">
              <img
                src="../images/scrapmarket.png"
                className="app-logo"
                alt="drs-logo"
              />
              <div className="flex column align-start ">
                <b>Metabins</b>
                <p className="small-text grey-text">
                  IOT Enabled Smart Bins Software
                </p>
              </div>
            </div>
          </a>

          <a href="https://drs.cerclex.com">
            <div className="app-box  ">
              <img
                src="../images/drs.png"
                className="app-logo"
                alt="drs-logo"
              />
              <div className="flex column align-start ">
                <b>DRS</b>
                <p className="small-text grey-text ">Deposit Return Scheme</p>
              </div>
            </div>
          </a>

          <a href="http://trace.cerclex.com/">
            <div className="app-box  ">
              <img
                src="../images/planetwarrior.jpg"
                className="app-logo"
                alt="drs-logo"
              />
              <div className="flex column align-start ">
                <b>Planet Warrior</b>
                <p className="small-text grey-text">Citizen Enabled Sanitation App</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default AccountCreated;
