import axios from "axios";
import { authenticationService } from "./config";

function getAuthURL() {
  return `${authenticationService.baseURL}`;
}

function getAuth() {
  let Auth = axios.create(authenticationService);
  const token = localStorage.getItem("CCXT");
  if (token) Auth.defaults.headers.common["X-Authentication-Token"] = token;
  return Auth;
}

export const Auth = getAuth();
export const AuthURL = getAuthURL();
